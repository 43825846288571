import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const layoutData = {
  props: { },
  loaders: {
    mainContent: `<img src="loading.gif">`,
  }
};



const routes = constructRoutes(microfrontendLayout, layoutData);
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => System.import(name)
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();

start();
